import { Flex } from '@chakra-ui/react'

// import { ProjectsAPI } from '@miimosa/api'
// import { FrontSearchProjectType, v3toFrontSearchProject } from '@miimosa/common'

import Faq from '@components/Faq'
import Form from '@components/Form'
import Hero from '@components/Hero'
import HowItWorks from '@components/HowItWorks'
import Mission from '@components/Mission'
// import Projects from '@components/Projects'

// const fetchProjects = async () => {
//   const projectsAPI = new ProjectsAPI()

//   try {
//     const results = await projectsAPI.getProjectsFromSearch({
//       query: '',
//       type_filter: 'PROJECT_TYPE_DONATION',
//       categories_filter: [],
//       around_me_filter: undefined,
//       pagination: undefined,
//       state_filter: ['PROJECT_STATE_PUBLISHED', 'PROJECT_STATE_FUNDED', 'PROJECT_STATE_FAILED'],
//       regional_code_filter: [],
//       preset: 'PRESET_UNSPECIFIED',
//       legacy_pagination: {
//         items_per_page: 6,
//         page: 1,
//       },
//       campaign_filter: [Number(process.env.CAMPAIGN_ID)],
//       labels_filter: [],
//       branch_code: 'mii_fr',
//       locale: 'fr',
//       collect_type_filter: 'COLLECT_TYPE_DONATION',
//       partner_filter: [],
//       hide_donation_under_progress_percentage: undefined,
//       collect_goal_type_filter: ['COLLECT_GOAL_TYPE_AMOUNT'],
//       slug_filter: [],
//     })

//     return results.kind === 'success' ? results.data.projects : []
//   } catch (err) {
//     return []
//   }
// }

// export const getServerSideProps = async () => {
// const projects = await fetchProjects()
// const formattedProjects = projects.map(v3toFrontSearchProject)
// return {
//   props: {
//     projects: formattedProjects,
//   },
// }
// }

// const Home = ({ projects }: { projects: FrontSearchProjectType[] }) => {
const Home = () => {
  return (
    <Flex direction="column" w="full" h="full">
      <Hero />
      <Mission />
      <HowItWorks />
      <Form />
      {/* <Projects projects={projects} /> */}
      <Faq />
    </Flex>
  )
}

export default Home
